import { CalculationRequest } from "../models/calculationRequest";
import { toast } from "react-toastify";

export const runCalculation = async (request: CalculationRequest) => {
  return await callApi("/calculate", request);
};

const callApi = async (uri: string, payload: any) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: payload ? JSON.stringify(payload) : null,
  };

  let apiUrl = process.env.REACT_APP_API_URL;
  if (!apiUrl?.length) {
    apiUrl = "https://greenbuffers-dev-api.azurewebsites.net" + uri;
  } else {
    apiUrl += uri;
  }

  //apiUrl = "https://localhost:7243" + uri;

  const response = (await fetch(apiUrl, options).catch((err) => {
    toast.error(
      "Kunde inte genomföra beräkningen just nu. Fel: " + err.message
    );
  })) as Response;

  if (response.status === 200) {
    return response.json();
  }

  return false;
};
