import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { colors } from "../../components/Colors";
import styled from "styled-components";

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0;
`;

export const Loading = () => (
  <LoadingContainer>
    <FontAwesomeIcon
      icon={solid("circle-notch")}
      spin={true}
      size={"8x"}
      color={colors.green}
    />
  </LoadingContainer>
);
