import styled from "styled-components";
import { colors } from "./Colors";

export const Form = styled.div`
  .input-group {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    position: relative;

    label {
      text-transform: uppercase;
      margin-bottom: 5px;
      font-weight: 300;
      position: absolute;
      top: 10px;
      left: 1rem;
      font-size: 0.7rem;
    }

    &:first-of-type {
      margin-top: 0;
    }

    span {
      position: absolute;
      right: 2px;
      top: 2px;
      bottom: 2px;
      background-color: ${colors.lightGray};
      width: 50px;
      text-align: center;
      padding: 1.8rem 0rem 0.5rem;
      font-size: 0.9rem;
    }
  }

  input,
  select {
    padding: 1.8rem 1rem 0.5rem;
    border: 2px solid ${colors.lightGray};
    border-radius: 4px;
    font-size: 1rem;
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    transition: border 250ms ease-in-out 0s;
  }

  &.sm {
    .input-group {
      label {
        font-size: 0.6rem;
        left: 0.8rem;
      }

      span {
        padding: 1.4rem 0rem 0.5rem;
        font-size: 0.8rem;
      }
    }

    input,
    select {
      padding: 1.4rem 0.8rem 0.5rem;
      border: 2px solid ${colors.lightGray};
      border-radius: 4px;
      font-size: 0.8rem;
      font-family: "Ubuntu", sans-serif;
      font-weight: 400;
      transition: border 250ms ease-in-out 0s;
    }

    small {
      font-size: 0.65rem;
    }
  }

  small {
    color: #999;
    font-size: 0.7rem;
  }
`;
