import React, { ChangeEvent } from "react";

interface FieldProps {
  title: string;
  name: string;
  defaultValue?: any;
  suffix?: string;
}

interface InputProps extends FieldProps {
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
}

interface DropdownProps extends FieldProps {
  values: { value: any; title: string }[];
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  title,
  name,
  defaultValue,
  onChange,
  values,
}) => {
  return (
    <div className="input-group">
      <label>{title}</label>
      <select
        name={name}
        value={defaultValue}
        onChange={(e) => onChange && onChange(e)}
      >
        {values.map((val) => (
          <option key={val.value} value={val.value}>
            {val.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export const Input: React.FC<InputProps> = ({
  title,
  name,
  defaultValue,
  onChange,
  type = "text",
  readOnly,
  suffix,
}) => {
  return (
    <div className="input-group">
      <label>{title}</label>
      {suffix && <span>{suffix}</span>}
      <input
        type={type}
        step=".001"
        name={name}
        defaultValue={defaultValue}
        readOnly={readOnly}
        onChange={(e) => onChange && onChange(e)}
      />
    </div>
  );
};
