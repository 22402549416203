import styled, { keyframes } from "styled-components";
import { Row } from "./Row";
import { Column } from "./Column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";

const slide = keyframes`
  0% {
    top: -65px;
  }
  100% {
    top: 5px;
  }
`;

const NavigationContainer = styled.div`
  animation: ${slide} 600ms forwards;
  position: fixed;
  background-color: white;
  width: calc(100% + 14px);
  height: 65px;
  -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
  margin: -7px;
  margin-bottom: 10px;
  padding: 7px;
  z-index: 200;
`;

const LogoContainer = styled.div`
  margin: -24px 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    max-height: 65px;
  }

  h1 {
    margin: 0 0 0 10px;
    padding: 0;
    font-weight: 300;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const MenuButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 150px;
`;

const fadeInBg = keyframes`
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(249, 249, 249, 1);
  }
`;

const MenuButton = styled.div`
  width: 120px;
  height: 77px;
  margin-top: -31px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    animation: ${fadeInBg} 600ms;
    background-color: #f9f9f9;
    cursor: pointer;
  }

  svg {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }
`;

interface Props {
  buttonClicked: (btn: string) => void;
}

export const Navigation: React.FC<Props> = ({ buttonClicked }) => {
  return (
    <NavigationContainer>
      <Row align={{ lg: "center" }}>
        <Column size={{ lg: 8 }}>
          <Menu>
            <LogoContainer>
              <h1>Green Buffers</h1>
            </LogoContainer>

            <MenuButtons>
              <MenuButton
                title="Startsidan"
                onClick={() => buttonClicked("home")}
              >
                <FontAwesomeIcon icon={solid("house")} />
                <span>Hem</span>
              </MenuButton>
            </MenuButtons>
          </Menu>
        </Column>
      </Row>
    </NavigationContainer>
  );
};
