import { ReactNode } from "react";

interface Props {
  size?: { xs?: number; sm?: number; md?: number; lg?: number };
  offset?: { xs?: number; sm?: number; md?: number; lg?: number };
  overflow?: "default" | "hidden";
  className?: any;
  style?: any;
  children?: ReactNode;
}

const generateSizeNames = (
  prefix: string,
  xs?: number,
  sm?: number,
  md?: number,
  lg?: number,
  suffix?: string
) => {
  const sizes = [];

  if (xs) sizes.push(`${prefix}-xs${suffix ? `-${suffix}` : ""}-${xs}`);
  if (sm) sizes.push(`${prefix}-sm${suffix ? `-${suffix}` : ""}-${sm}`);
  if (md) sizes.push(`${prefix}-md${suffix ? `-${suffix}` : ""}-${md}`);
  if (lg) sizes.push(`${prefix}-lg${suffix ? `-${suffix}` : ""}-${lg}`);

  return sizes.join(" ");
};

export const Column: React.FC<Props> = ({
  children,
  size,
  offset,
  className,
  overflow,
  style,
}) => (
  <div
    className={[
      `${generateSizeNames(
        "col",
        size?.xs,
        size?.sm,
        size?.md,
        size?.lg
      )} ${generateSizeNames(
        "col",
        offset?.xs,
        offset?.sm,
        offset?.md,
        offset?.lg,
        "offset"
      )}`,
      className,
    ].join(" ")}
    style={
      overflow
        ? { overflow: overflow === "default" ? "auto" : "hidden", ...style }
        : { ...style }
    }
  >
    {children}
  </div>
);
