import React from "react";
import { Navigation } from "./components/Navigation";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AppContainer = styled.div`
  padding-top: 80px;
`;

function App() {
  const navigate = useNavigate();

  const navButtonClicked = (btn: string) => {
    switch (btn) {
      case "home":
        navigate("/", { replace: true });
        break;
      default:
        console.log("nav button not implemented yet: " + btn);
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <Navigation buttonClicked={navButtonClicked} />
      <Outlet />
    </div>
  );
}

export default App;
