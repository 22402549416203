import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "@drewbot/sass-flexbox-grid/public/sass-flexbox/main.min.css";
import "./assets/scss/shared.scss";
import { Router } from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);
