import React, {ReactNode} from 'react';
import styled from "styled-components";

const CardContainer = styled.div`
  padding: 20px 30px;
  z-index: 10;
  margin-top: 60px;
  background-color: white;
`;

interface Props {
  children: ReactNode;
}

export const Card: React.FC<Props> = ({children}) => (
  <CardContainer>
    {children}
  </CardContainer>
)