import { useState } from "react";
import { Card } from "../../components/Card";
import { Column } from "../../components/Column";
import { Form } from "../../components/Form";
import { Row } from "../../components/Row";
import { Input } from "../../components/TextInput";

interface Props {
  onLogin: (str: string) => void;
}
export const Login: React.FC<Props> = ({ onLogin }) => {
  const [str, setStr] = useState("");

  return (
    <Row>
      <Column size={{ lg: 3 }} style={{ margin: "0 auto" }}>
        <Card>
          <strong>LOGGA IN</strong>
          <Form style={{ marginTop: "10px" }}>
            <div style={{ marginBottom: "10px" }}>
              <Input
                name="password"
                title="Password"
                type="password"
                onChange={(e) => setStr(e.target.value)}
              />
            </div>
            <button
              style={{ marginTop: "10px" }}
              className="primary sm"
              type="button"
              onClick={() => onLogin(str)}
            >
              Login
            </button>
          </Form>
        </Card>
      </Column>
    </Row>
  );
};
