import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

const TabContent = styled.div`
  display: block;
  border: 1px solid #eee;
  padding: 10px;
  padding-bottom: 20px;
`;

const TabButtons = styled.div``;

const TabButton = styled.button`
  font-family: "Ubuntu", sans-serif;
  padding: 10px;
  margin-top: -1px;
  background-color: #fafafa;
  border: 0;
  border-top: 1px solid #eee;
  cursor: pointer;

  &.clicked {
    background-color: #eee;
  }

  &:first-of-type {
    border-bottom-left-radius: 4px;
  }

  &:last-of-type {
    border-bottom-right-radius: 4px;
  }
`;

interface Props {
  children: ReactNode;
}

interface TabProps {
  title: string;
  children: ReactNode;
  style?: any;
}

export const Tab: React.FC<TabProps> = ({ children, style }) => {
  return <div style={style}>{children}</div>;
};

export const Tabs: React.FC<Props> = ({ children }) => {
  const [isMultiple] = useState(Array.isArray(children));
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div>
      <TabContent>
        {isMultiple && (children as any)[selectedIndex]}
        {!isMultiple && children}
      </TabContent>
      <TabButtons>
        {isMultiple &&
          (children as any).map((tab: any, idx: number) => (
            <TabButton
              type="button"
              key={idx}
              onClick={() => setSelectedIndex(idx)}
              className={selectedIndex == idx ? "clicked" : ""}
            >
              {tab.props.title}
            </TabButton>
          ))}
      </TabButtons>
    </div>
  );
};
