import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  tbody {
    tr {
      height: 50px;
      border-bottom: 1px solid #f9f9f9;

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
`;

interface Props {
  children: ReactNode;
  data?: Array<any>;
}

interface TableColumnProps {
  title: string;
  property?: string;
  template?: (data: any) => ReactNode;
  data?: any;
  width?: string;
  align?: "left" | "center" | "right";
}

export const TableColumn: React.FC<TableColumnProps> = ({
  property,
  template,
  data,
}) => {
  return (
    <>
      {template && template(data)}
      {!template && property && data[property]}
    </>
  );
};

const getTitles = (columns: any): any => {
  return columns.map((col: any) => ({ ...col.props }));
};

export const Table: React.FC<Props> = ({ children, data }) => {
  const [childrenArray, setChildrenArray] = useState(
    !Array.isArray(children) ? [children] : children
  );
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    setChildrenArray(!Array.isArray(children) ? [children] : children);
  }, [children, data]);

  useEffect(() => {
    setColumns(getTitles(childrenArray));
  }, [childrenArray]);

  return (
    <TableContainer>
      <thead>
        <tr>
          {columns.map((col: any, idx: number) => (
            <th
              key={col.title + "_" + idx}
              style={{ width: col.width, textAlign: col.align ?? "left" }}
            >
              {col.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, rowIdx: number) => (
            <tr key={rowIdx}>
              {columns.map((col: any, colIdx: number) => (
                <td
                  key={col.title + "_" + colIdx}
                  style={{ textAlign: col.align ?? "left" }}
                >
                  <TableColumn
                    title={col.title}
                    property={col.property}
                    template={col.template}
                    align={col.align}
                    data={row}
                  />
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </TableContainer>
  );
};
