import React, { ReactNode } from "react";
import styled from "styled-components";

const TitleContainer = styled.div`
  padding: 20px 30px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 100;
  position: relative;
  top: -35px;
  background-color: white;
  display: inline;
`;

interface Props {
  style?: any;
  children: ReactNode;
}

export const Title: React.FC<Props> = ({ children, style }) => (
  <TitleContainer style={{ ...style }}>{children}</TitleContainer>
);
