import React, {ReactNode} from 'react';

interface Props {
  align?: {
    xs?: 'left' | 'center' | 'right' | 'around';
    sm?: 'left' | 'center' | 'right' | 'around';
    md?: 'left' | 'center' | 'right' | 'around';
    lg?: 'left' | 'center' | 'right' | 'around';
  };
  children: ReactNode;
}

const generateSizeNames = (alignXs?: any, alignSm?: any, alignMd?: any, alignLg?: any) => {
  const sizes = [];

  if (alignXs) sizes.push(`${alignXs}-xs`);
  if (alignSm) sizes.push(`${alignSm}-sm`);
  if (alignMd) sizes.push(`${alignMd}-md`);
  if (alignLg) sizes.push(`${alignLg}-lg`);

  return sizes.join(' ');
}

export const Row: React.FC<Props> = ({children, align}) => (
  <div className={`row ${generateSizeNames(align?.xs, align?.sm, align?.md, align?.lg)}`}>{children}</div>
)