import { useEffect, useState } from "react";
import { AppContainer } from "../App";
import { Card } from "../components/Card";
import { Column } from "../components/Column";
import { Row } from "../components/Row";
import { Tab, Tabs } from "../components/Tabs";
import { Title } from "../components/Title";
import { runCalculation } from "../services/BackendService";
import {
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryStack,
  VictoryTheme,
  VictoryVoronoiContainer,
} from "victory";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { Loading } from "./components/Loading";
import { validateLocaleAndSetLanguage } from "typescript";
import { Table, TableColumn } from "../components/Table";
import { Login } from "./components/Login";
import { Input } from "../components/TextInput";
import { CalculationRequest } from "../models/calculationRequest";
import { Form } from "../components/Form";

const ChartContainer = styled.div`
  width: 100%;
`;

const colors = [
  "#03045e",
  "#023e8a",
  "#0077b6",
  "#0096c7",
  "#00b4d8",
  "#48cae4",
  "#90e0ef",
  "#ade8f4",
  "#caf0f8",
];

const translate = (key: string) => {
  switch (key) {
    case "speed":
      return "Speed";
    case "collidingTrainCount":
      return "Colliding Train Size";
    case "stationaryTrainCount":
      return "Stationary Train Size";
    case "collidingTrainMass":
      return "Colliding Carriage Weight";
    case "stationaryTrainMass":
      return "Stationary Carriage Weight";
    default:
      return "Unknown";
  }
};

const appendSuffix = (key: string, value: any) => {
  switch (key) {
    case "speed":
      return `${value} km/h`;
    case "collidingTrainCount":
      return `${value} pcs`;
    case "stationaryTrainCount":
      return `${value} pcs`;
    case "collidingTrainMass":
      return `${value} kg`;
    case "stationaryTrainMass":
      return `${value} kg`;
    default:
      return "Unknown";
  }
};

const firstCarriageCouplings = [
  {
    isReversible: true,
    forceDampening: 1500000,
    deformationLength: 0.2,
    step: 0,
  },
  {
    isReversible: false,
    forceDampening: 1800000,
    deformationLength: 0.7,
    step: 1,
  },
  {
    isReversible: false,
    forceDampening: 2000000,
    deformationLength: 1.2,
    step: 2,
  },
  {
    isReversible: false,
    forceDampening: 4500000,
    deformationLength: 0.5,
    step: 3,
  },
];

const otherCarriageCouplings = [
  {
    isReversible: false,
    forceDampening: 1500000,
    deformationLength: 0.3,
    step: 0,
  },
  {
    isReversible: false,
    forceDampening: 1700000,
    deformationLength: 0.4,
    step: 1,
  },
  {
    isReversible: false,
    forceDampening: 2250000,
    deformationLength: 0.1,
    step: 2,
  },
];

export const HomePage = () => {
  const [request, setRequest] = useState<CalculationRequest>({
    speed: 16,
    stepSize: 0.001,
    collidingTrain: [
      {
        name: "LOC",
        mass: 48000,
        type: 0,
        couplings: firstCarriageCouplings,
        index: 0,
      },
      {
        name: "PV1",
        mass: 48000,
        type: 0,
        couplings: otherCarriageCouplings,
        index: 1,
      },
      {
        name: "PV2",
        mass: 48000,
        type: 0,
        couplings: otherCarriageCouplings,
        index: 2,
      },
      {
        name: "PV3",
        mass: 48000,
        type: 0,
        couplings: otherCarriageCouplings,
        index: 3,
      },
    ],
    stationaryTrain: [
      {
        name: "SLOC",
        mass: 48000,
        type: 1,
        couplings: otherCarriageCouplings,
        index: 0,
      },
      {
        name: "SV1",
        mass: 48000,
        type: 1,
        couplings: otherCarriageCouplings,
        index: 1,
      },
      {
        name: "SV2",
        mass: 48000,
        type: 1,
        couplings: otherCarriageCouplings,
        index: 2,
      },
      {
        name: "SV3",
        mass: 48000,
        type: 1,
        couplings: otherCarriageCouplings,
        index: 3,
      },
    ],
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [calculation, setCalculation] = useState<any>();
  const [response, setResponse] = useState<any>();

  const login = (str: string) => {
    if (str === "20222022") {
      setIsLoggedIn(true);
    }
  };

  const calculate = () => {
    setIsCalculating(true);
    runCalculation(request).then((data) => {
      setIsCalculating(false);

      const graph = [] as any[];
      for (const row of data.data) {
        let item = graph.find((x) => x.name === row.carriage);
        if (!item) {
          item = {
            name: row.carriage,
            energy_0: 0,
            energy_1: 0,
            energy_2: 0,
            energy_3: 0,
          };
          graph.push(item);
        }

        item["energy_" + row.step] = Math.round(row.energy * 100) / 100;
      }

      setCalculation(graph);
      setResponse(data);

      console.log(data);
    });
  };

  return (
    <AppContainer>
      {isLoggedIn && (
        <Row>
          <Column size={{ lg: 3 }} offset={{ lg: 1 }}>
            <Card>
              <Title>Configuration</Title>
              <Form style={{ marginBottom: "20px" }}>
                <Input
                  name="speed"
                  defaultValue={request.speed}
                  title="Speed of the colliding train"
                  type="text"
                  onChange={(e) => {
                    if (parseInt(e.target.value)) {
                      setRequest({
                        ...request,
                        speed: parseInt(e.target.value),
                      });
                    }
                  }}
                  suffix="km/h"
                />
                <small>Sets the speed of the colliding train</small>
                <Input
                  name="sampleSize"
                  defaultValue={request.stepSize}
                  title="Sample rate"
                  type="text"
                  onChange={(e) => {
                    if (e.target.value.length) {
                      if (
                        e.target.value.endsWith(".") ||
                        e.target.value.endsWith(",")
                      ) {
                        setRequest({
                          ...request,
                          stepSize: parseFloat(`${e.target.value}01`),
                        });
                      } else {
                        setRequest({
                          ...request,
                          stepSize: parseFloat(e.target.value),
                        });
                      }
                    } else {
                      setRequest({
                        ...request,
                        stepSize: 0,
                      });
                    }
                  }}
                  suffix="s"
                />
                <small>
                  Define the sample rate in seconds. Default: 0,001 s.
                </small>
              </Form>

              <Tabs>
                <Tab title="Colliding train">
                  {request.collidingTrain
                    .sort((a, b) => a.index! - b.index!)
                    .map((car) => (
                      <div key={car.name} style={{ marginBottom: "40px" }}>
                        <h3>{car.name}</h3>
                        <Form
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Input
                            name="weight"
                            defaultValue={car.mass}
                            title="Weight"
                            type="text"
                            onChange={(e) => {
                              if (e.target.value.length) {
                                setRequest({
                                  ...request,
                                  collidingTrain: [
                                    ...request.collidingTrain.filter(
                                      (x) => x !== car
                                    ),
                                    {
                                      ...car,
                                      mass: parseInt(e.target.value),
                                    },
                                  ],
                                });
                              }
                            }}
                            suffix="kg"
                          />
                          {car.couplings
                            .sort((a, b) => a.step! - b.step!)
                            .map((coupling, index) => (
                              <div
                                key={coupling.step}
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                <h4 style={{ marginBottom: "4px" }}>
                                  COUPLING {index + 1}
                                </h4>
                                <Input
                                  name={`forceDampening_` + index}
                                  defaultValue={coupling.forceDampening / 1000}
                                  title={`Dampening force ${index + 1} (${
                                    coupling.isReversible
                                      ? "reversible"
                                      : "non-reversible"
                                  })`}
                                  type="text"
                                  onChange={(e) => {
                                    if (e.target.value.length) {
                                      setRequest({
                                        ...request,
                                        collidingTrain: [
                                          ...request.collidingTrain.filter(
                                            (x) => x !== car
                                          ),
                                          {
                                            ...car,
                                            couplings: [
                                              ...car.couplings.filter(
                                                (x) => x !== coupling
                                              ),
                                              {
                                                ...coupling,
                                                forceDampening:
                                                  parseInt(e.target.value) *
                                                  1000,
                                              },
                                            ],
                                          },
                                        ],
                                      });
                                    }
                                  }}
                                  suffix="kN"
                                />
                                <Input
                                  name={`deformationLength_` + index}
                                  defaultValue={coupling.deformationLength}
                                  title={`Deformation length ${index + 1}`}
                                  type="text"
                                  onChange={(e) => {
                                    if (e.target.value.length) {
                                      const fixedCoupling = {
                                        ...coupling,
                                      };

                                      if (
                                        e.target.value.endsWith(".") ||
                                        e.target.value.endsWith(",")
                                      ) {
                                        fixedCoupling.deformationLength =
                                          parseFloat(`${e.target.value}1`);
                                      } else {
                                        fixedCoupling.deformationLength =
                                          parseFloat(e.target.value);
                                      }

                                      setRequest({
                                        ...request,
                                        collidingTrain: [
                                          ...request.collidingTrain.filter(
                                            (x) => x !== car
                                          ),
                                          {
                                            ...car,
                                            couplings: [
                                              ...car.couplings.filter(
                                                (x) => x !== coupling
                                              ),
                                              fixedCoupling,
                                            ],
                                          },
                                        ],
                                      });
                                    }
                                  }}
                                  suffix="m"
                                />
                              </div>
                            ))}
                        </Form>
                      </div>
                    ))}
                </Tab>
                <Tab title="Stationary train">
                  {request.stationaryTrain
                    .sort((a, b) => a.index! - b.index!)
                    .map((car) => (
                      <div key={car.name} style={{ marginBottom: "40px" }}>
                        <h3>{car.name}</h3>
                        <Form
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Input
                            name="weight"
                            defaultValue={car.mass}
                            title="Weight"
                            type="text"
                            onChange={(e) => {
                              if (e.target.value.length) {
                                setRequest({
                                  ...request,
                                  stationaryTrain: [
                                    ...request.collidingTrain.filter(
                                      (x) => x !== car
                                    ),
                                    {
                                      ...car,
                                      mass: parseInt(e.target.value),
                                    },
                                  ],
                                });
                              }
                            }}
                            suffix="kg"
                          />
                          {car.couplings
                            .sort((a, b) => a.step! - b.step!)
                            .map((coupling, index) => (
                              <div
                                key={coupling.step}
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                <h4 style={{ marginBottom: "4px" }}>
                                  COUPLING {index + 1}
                                </h4>
                                <Input
                                  name={`forceDampening_` + index}
                                  defaultValue={coupling.forceDampening / 1000}
                                  title={`Dampening force ${index + 1} (${
                                    coupling.isReversible
                                      ? "reversible"
                                      : "non-reversible"
                                  })`}
                                  type="text"
                                  onChange={(e) => {
                                    if (e.target.value.length) {
                                      setRequest({
                                        ...request,
                                        stationaryTrain: [
                                          ...request.collidingTrain.filter(
                                            (x) => x !== car
                                          ),
                                          {
                                            ...car,
                                            couplings: [
                                              ...car.couplings.filter(
                                                (x) => x !== coupling
                                              ),
                                              {
                                                ...coupling,
                                                forceDampening:
                                                  parseInt(e.target.value) *
                                                  1000,
                                              },
                                            ],
                                          },
                                        ],
                                      });
                                    }
                                  }}
                                  suffix="kN"
                                />
                                <Input
                                  name={`deformationLength_` + index}
                                  defaultValue={coupling.deformationLength}
                                  title={`Deformation length ${index + 1}`}
                                  type="text"
                                  onChange={(e) => {
                                    if (e.target.value.length) {
                                      const fixedCoupling = {
                                        ...coupling,
                                      };

                                      if (
                                        e.target.value.endsWith(".") ||
                                        e.target.value.endsWith(",")
                                      ) {
                                        fixedCoupling.deformationLength =
                                          parseFloat(`${e.target.value}1`);
                                      } else {
                                        fixedCoupling.deformationLength =
                                          parseFloat(e.target.value);
                                      }

                                      setRequest({
                                        ...request,
                                        stationaryTrain: [
                                          ...request.collidingTrain.filter(
                                            (x) => x !== car
                                          ),
                                          {
                                            ...car,
                                            couplings: [
                                              ...car.couplings.filter(
                                                (x) => x !== coupling
                                              ),
                                              fixedCoupling,
                                            ],
                                          },
                                        ],
                                      });
                                    }
                                  }}
                                  suffix="m"
                                />
                              </div>
                            ))}
                        </Form>
                      </div>
                    ))}
                </Tab>
              </Tabs>
            </Card>
          </Column>
          <Column size={{ lg: 7 }} style={{ marginLeft: "10px" }}>
            <Card>
              <Title>Calculation</Title>
              <div>
                <button
                  type="button"
                  className="primary"
                  onClick={() => {
                    calculate();
                  }}
                  style={{
                    margin: "10px 0 10px 15px",
                  }}
                  title="Execute calculation"
                >
                  Calculate
                </button>
              </div>
              {isCalculating && <Loading />}
              {calculation && (
                <>
                  <Row>
                    <Column size={{ lg: 12 }} style={{ padding: "0px" }}>
                      <strong>FORCE ABSORBED BY GREEN BUFFERS</strong>
                      <ResponsiveContainer height={500} width="100%">
                        <BarChart
                          data={calculation}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar
                            dataKey={`energy_0`}
                            stackId="a"
                            unit=" kN"
                            fill="#03045e"
                            label="Step 1"
                            name="Step 1"
                          />
                          <Bar
                            dataKey={`energy_1`}
                            stackId="a"
                            unit=" kN"
                            fill="#023e8a"
                            label="Step 2"
                            name="Step 2"
                          />
                          <Bar
                            dataKey={`energy_2`}
                            stackId="a"
                            unit=" kN"
                            fill="#0077b6"
                            label="Step 3"
                            name="Step 3"
                          />
                          <Bar
                            dataKey={`energy_3`}
                            stackId="a"
                            unit=" kN"
                            fill="#0096c7"
                            label="Step 4"
                            name="Step 4"
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Column>
                  </Row>
                  <Row>
                    <Column size={{ lg: 12 }}>
                      <Table
                        data={
                          response &&
                          Object.keys(response)
                            .filter((x) => x !== "data")
                            .map((row: any) => ({
                              key: row,
                              value: response[row],
                            }))
                        }
                      >
                        <TableColumn
                          title="Parameter"
                          property="key"
                          template={(data) => (
                            <span>{translate(data["key"])}</span>
                          )}
                        />
                        <TableColumn
                          title="Value"
                          property="value"
                          align="right"
                          template={(data) => (
                            <span>
                              {appendSuffix(data["key"], data["value"])}
                            </span>
                          )}
                        />
                      </Table>
                    </Column>
                  </Row>
                </>
              )}
            </Card>
          </Column>
        </Row>
      )}
      {!isLoggedIn && <Login onLogin={login} />}
    </AppContainer>
  );
};
